<template>
  <div class="send">
    <div class="send-message">
      <div class="send-message__input">
        <div class="send-message__title">{{ $t('message.label') }}</div>
        <base-input
          ref="input"
          v-model="sendingText"
          type="textarea"
          :placeholder="$t('message.placeholder')"
        />
        <div class="send-message__container">
          <div>
            <vue-dropzone
              id="dropzone"
              ref="Dropzone"
              class="dropzone"
              :duplicate-check="true"
              :include-styling="false"
              :use-custom-slot="true"
              :options="dropzoneOptions"
              @vdropzone-file-added="fileAdded"
              @vdropzone-success-multiple="success"
            >
              <div class="dropzone-container">
                <plain-button
                  v-if="this.$store.getters['moduleSupport/GET_FILES'].length < 5"
                  color="primary"
                  class="dropzone-container__file-link"
                >
                  <div class="send-message__text">
                    <svg-icon name="clip" class="send-message__icon" />
                    {{ $t('add_file') }}
                  </div>
                </plain-button>
              </div>
            </vue-dropzone>
            <AttachmentList
              class="dropzone__list"
              :temp-attachments="getTempAttachments"
              :attachments="getAttachments"
            />
          </div>
          <div class="send-message__send">
            <base-button
              v-if="arhive"
              theme="outlined"
              :hidden="arhive"
              class="send-message__send-btn"
              :loading="sendingMessageToArhiv"
              @click="askSure()"
            >
              {{ $t('btn.arh') }}
            </base-button>
            <base-button
              class="send-message__send-btn"
              :disabled="!sendingText.length"
              :loading="sendingMessage"
              @click="sendMessageTo()"
            >
              {{ $t('btn.send') }}
            </base-button>
          </div>
        </div>
      </div>
      <!--    </div>-->
    </div>
  </div>
</template>

<script>
/**
 * @TODO:
 * - сравить NewTicket и SendMessage, вынести повторяющийся код или рассмотреть возможность использования одного компонента с разными настройками
 */
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import vue2Dropzone from 'vue2-dropzone';
import AttachmentList from './AttachmentList';
import BaseLoader from '@/components/BaseLoader/BaseLoader';

export default {
  name: 'SendMessage',
  components: {
    BaseInput,
    vueDropzone: vue2Dropzone,
    AttachmentList,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    arhive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: '',
      status: '',
      sendingText: '',
      params: {},
      sendingMessage: false,
      sendingMessageToArhiv: false,
      tempAttachments: [],
      attachments: [],
      dropzoneOptions: {
        url: `${this.$store.state.moduleApp.host}/billmgr`,
        method: 'post',
        maxFilesize: 10,
        maxFiles: 5,
        autoProcessQueue: false,
        clickable: true,
        includeStyling: false,
        previewsContainer: false,
        uploadMultiple: true,
        headers: {
          'Content-Type': 'application/json',
        },
        parallelUploads: 5,
        // acceptedFiles:
        // '.jpeg,.jpg,.png,.gif,.JPEG,.JPG,.PNG,.GIF,.pdf,.doc,.docx, .zip, .txt, .rar ',
        // '.*',
      },
    };
  },
  computed: {
    currentId() {
      return this.$store.getters['moduleSupport/GET_CURRENT_TICKET_ID'];
    },
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    },
  },
  watch: {
    // sendingText: function () {
    //   this.$refs.input.style.height = 'auto';
    //   this.$nextTick(() => {
    //     this.$refs.input.style.height = this.$refs.input.scrollHeight + 'px';
    //   });
    // },
  },
  methods: {
    sendMessageTo() {
      this.sendingMessage = true;
      const files = this.$refs.Dropzone.getQueuedFiles();
      let formData = new FormData();
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          formData.append(`file_${i + 1}`, file);
          formData.delete(`file[${i}]`);
        }
      }
      formData.append('elid', this.currentId);
      formData.append('auth', localStorage.getItem('token'));
      formData.append('sok', 'ok');
      formData.append('message', this.sendingText);
      formData.append('out', 'bjson');
      formData.append('func', 'clientticket.edit');
      if (this.sendingText.length > 0) {
        this.$store
          .dispatch('moduleSupport/sendMessage', formData)
          .then(data => {
            if (data.ok) {
              return Promise.all([
                this.$store.dispatch('moduleSupport/fetchCurrentTicket', this.currentId),
              ])
                .then(() => setTimeout(() => (this.isSending = false), 500))
                .then(() => (this.sendingText = ''))
                .then(() => (this.attachments = []))
                .then(() => this.$refs.Dropzone.removeAllFiles())
                .then(() => (this.tempAttachments = []))
                .then(() => this.$store.dispatch('moduleSupport/resetFiles'))
                .then(() => this.$nextTick());
            } else this.showError('error.other');
          })
          .catch(e => {
            this.showError(e);
            this.isSending = false;
          })
          .finally(() =>
            setTimeout(() => {
              this.sendingMessage = false;
            }, 1500)
          );
      } else {
        this.showError(this.$t('error.null'));
        return setTimeout(() => {
          this.sendingMessage = false;
        }, 1500);
      }
    },
    showError(text) {
      this.$modals.open({
        name: 'Error',
        title: this.$t('error.title'),
        text: text,
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    fileAdded(file) {
      let attachment = {};
      attachment.file = file;
      attachment._id = file.upload.uuid;
      attachment.title = file.name;
      attachment.type = 'file';
      attachment.extension = '.' + file.type.split('/')[1];
      attachment.isLoading = true;
      attachment.progress = null;
      attachment.size = file.size;
      if (
        attachment.size < 10 * 1024 * 1024 &&
        this.tempAttachments.length < 5 &&
        !this.tempAttachments.map(t => t.title).includes(attachment.title)
      ) {
        this.tempAttachments = [...this.tempAttachments, attachment];
        this.$store.dispatch('moduleSupport/addFiles', file);
      } else {
        this.showError(this.$t('error.text'));
      }
    },
    askSure() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      this.$modals.open({
        name: selfName,
        text: 'Вы хотите отправить в архив тикет?',
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                this.isProcessing = true;

                this.sendMessageToArhiv();
                self.component = null;
                self.closable = true;
                this.$store.dispatch('moduleSupport/fetchAllTickets');
                self.text = this.$t('modal.sure.success');
                // .then(() => {
                //   res = 'success';
                // })
                // .catch(() => (res = 'fail'))
                // .finally(() => {
                //   const delay = Date.now() - time < 1000 ? 1000 : 0;
                //   setTimeout(() => {
                //     self.component = null;
                //     self.closable = true;
                //     self.text = this.$t(`modal.sure.${res}`);
                //     self.footer = {
                //       centered: true,
                //       confirm: {
                //         props: { title: this.$t('modal.sure.close') },
                //         on: { click: () => this.$modals.close() },
                //       },
                //     };
                //   }, delay);
                // });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    async sendMessageToArhiv() {
      this.sendingMessageToArhiv = true;
      this.$store
        .dispatch('moduleSupport/sendMessageArh', {
          elid: this.currentId,
          func: 'clientticket.archive',
        })
        .then(async data => {
          if (data.ok) {
            return Promise.all([
              this.$store.dispatch('moduleSupport/fetchCurrentTicket', this.currentId),
            ])
              .then(() => setTimeout(() => (this.isSending = false), 500))
              .then(() => (this.sendingText = ''))
              .then(() => this.$store.dispatch('moduleSupport/fetchAllTickets'));
          }
        })
        .catch(e => {
          this.showError(e);
          this.isSending = false;
        })
        .finally(
          () =>
            setTimeout(() => {
              this.sendingMessageToArhiv = false;
            }, 1500),
          this.$router.push({ name: 'OpenTicket' })
        );
    },
    success() {
      this.tempAttachments = [];
      this.attachments = [];
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "message": {
      "label": "Задать уточняющий вопрос",
      "placeholder": "Содержимое"
    },
    "add_file": "Загрузить файл",
    "btn": {
      "send": "Отправить",
      "arh": "В архив"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    },
    "error": {
      "title": "Добавление файла",
      "other": "Произошла ошибка. Пожалуйста, попробуйте позже.",
      "null": "Сообщение не должно быть пустым",
      "text": "Добавляемых файлов должно быть не более 5-ти, размер каждого не должен превышать 10 МБ и они должны отличаться друг от друга"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.send {
  //width: 600px
  //max-height: 600px;
  display: flex;
}
.send-message {
  //width: 600px
  //max-height: 600px;
  max-height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.send-message__input {
  margin-top: 10px;
  max-height: auto;
}

.send-message__icon {
  width: 16px;
  margin-top: 26px;
  color: $color-light.light-blue;
}

.send-message__title {
  display: flex;
  font-size: $font-size-base;
  padding-bottom: 10px;
}

.send-message__send {
  flexy(flex-start, center, wrap, col);
  margin-bottom: 1.5rem;
  max-width: 260px;
  position: relative;
  +breakpoint(sm-and-up) {
    margin: 0 0 0 auto;
    flexy(flex-end);
  }
}

  .send-message__container {
    display: flex;
  }
.send-message__send-btn {
  //width: 100%;
  min-width: 120px;
  margin-top: 1.5rem;
  margin-left: 10px;
  +breakpoint(ms-and-up) {
    //margin-top: 60px;
  }
}

.send-message__text {
  font-size: 0.8rem;
}
</style>
